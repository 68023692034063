// import { Notify } from "vant";
// import router from "@/router";
import axios from 'axios';
// import {fbtoken} from '@/request/sportApi'
// import 

const instance = axios.create({
  // baseURL: 'https://app.server.st-newsports.com',
  // baseURL: 'https://v9iezty0ize3zmz.api.tyc12828.com',
  baseURL: localStorage.getItem('requestUrl'),
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    // 'Authorization': "tt_sFKu9Ba9oAJMiAp9qyFey32LsFhhYPEK.40ab7ded332f129d3dbc0e5689bea0a8"
  },
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
		// console.log(config, '==--==')
		if (!config.baseURL) {
			config.baseURL = localStorage.getItem('requestUrl')
		}
    // 可以在发送请求之前做些什么
		let token = localStorage.getItem("fbtoken")
		if (token) {
			config.headers['Authorization'] = token
		}
    return config;
  },
  error => {
    // 请求错误时做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    // 对响应数据做些什么
		if (response.status == 200 && response.data.success) {
			return response.data;
		} else {
			if (response.data.code == 14010) {
			// 	console.log(response)
			// 	return fbtoken()
			// 	.then(res => {
			// 		if (res.code == 0 && res?.data?.token) {
			// 			localStorage.setItem('fbtoken', res.data.token)
			// 			localStorage.setItem('serverInfo', JSON.stringify(res.data.serverInfo))
			// 			localStorage.setItem('requestUrl', res.data.serverInfo.apiServerAddress)
						// response.config.headers['Authorization'] = res.data.token
						delete response.config.headers.Authorization
						localStorage.removeItem('fbtoken')
						return axios.request(response.config)
						.then(r => {
							return r.data
						})
					// } else {
					// 	Notify({
					// 		message: res.msg,
					// 		duration: 2000,
					// 	})
					// 	location.replace('/#/login')
					// }
				// })
				// .catch(() => {
				// 	Notify({
				// 		message: "登录信息已过期，请重新登录",
				// 		duration: 2000,
				// 	})
				// 	location.replace('/#/login')
				// 	return Promise.reject();
				// })
			} else {
				return response.data.message || response.message
			}
		}
  },
  error => {
    // 响应错误时做些什么
    return Promise.reject(error);
  }
);

export default instance;
