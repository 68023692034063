var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabItem"},[_vm._m(0),_c('div',{staticClass:"topListItem"},_vm._l((_vm.homeHorizontal),function(item,index){return _c('div',{key:index,staticClass:"topItemList",on:{"click":function($event){return _vm.goLink(item.androidLink)}}},[_c('div',{staticClass:"logoImg"},[_c('img',{attrs:{"src":`https://testapi.broshanwbhxbvx.top/${item.icon}`}})]),_c('div',{staticClass:"topItemName"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"inItems"},[_vm._v("进入")])])}),0),_vm._m(1),_vm._l((_vm.homeSameCityVertical),function(item,index){return _c('div',{key:index,staticClass:"bomList",style:({
      'border-bottom':
        index == _vm.homeSameCityVertical.length - 1
          ? ''
          : '1px solid rgba(29, 33, 47, 1)',
    })},[_c('div',{staticClass:"listLeft"},[_c('img',{staticClass:"imgZt",attrs:{"src":`https://testapi.broshanwbhxbvx.top/${item.icon}`}}),_c('div',{staticClass:"leftPop"},[_vm._m(2,true),_c('div',[_vm._v(_vm._s(`附近: ${item.distance}km`))])])]),_c('div',{staticClass:"listRight"},[_c('div',{staticClass:"listRightOne"},[_c('div',{staticClass:"blueShu"}),_c('div',{staticClass:"rightName"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"ages"},[_vm._v(_vm._s(`${item.age}岁`))]),_c('div',{staticClass:"mmSw"},[_vm._v(_vm._s(item.price))])]),_c('div',{staticClass:"listRightOne rightTwo"},[_c('div',{staticClass:"rightTwoTit"},[_vm._v("价格")]),_c('div',{staticClass:"mmMoney"},[_c('span',[_vm._v(_vm._s(_vm.getCiMoney(item.describe)))]),_vm._v("/次 ")]),_c('div',{staticClass:"mmMoney"},[_c('span',[_vm._v(_vm._s(_vm.getDeyMoney(item.describe)))]),_vm._v("/夜 ")])]),_c('div',{staticClass:"labelLine"},_vm._l((_vm.setStringToAny(item.project)),function(desc,indexTwo){return _c('div',{key:indexTwo,staticClass:"labels",style:({
            background:
              indexTwo == 0
                ? 'rgba(227, 95, 0, 0.15)'
                : indexTwo == 1
                ? 'rgba(135, 75, 255, 0.15)'
                : 'rgba(58, 134, 255, 0.15)',
            color:
              indexTwo == 0
                ? 'rgba(227, 95, 0, 1)'
                : indexTwo == 1
                ? 'rgba(135, 75, 255, 1)'
                : 'rgba(29, 154, 238, 1)',
          })},[_vm._v(" "+_vm._s(desc)+" ")])}),0),_c('van-button',{staticClass:"btn",on:{"click":function($event){return _vm.goLink(item.androidLink)}}},[_c('div',{staticClass:"btnIn"},[_c('div',{staticClass:"btnIcon"},[_c('img',{attrs:{"src":require("@/assets/newHome/btnIcon.webp")}})]),_c('div',[_vm._v("点击开始约会")])])])],1)])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleLine",staticStyle:{"margin-top":"0"}},[_c('div',{staticClass:"titleImg"},[_c('img',{attrs:{"src":require("@/assets/newHome/gf.webp")}})]),_c('div',{staticClass:"titleName"},[_vm._v("官方推荐")]),_c('div',{staticClass:"titleTips"},[_vm._v("recommend")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleLine",staticStyle:{"margin-bottom":"5px"}},[_c('div',{staticClass:"titleImg"},[_c('img',{attrs:{"src":require("@/assets/newHome/title2.webp")}})]),_c('div',{staticClass:"titleName"},[_vm._v("情趣约炮")]),_c('div',{staticClass:"titleTips"},[_vm._v("online live")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dwIcon"},[_c('img',{attrs:{"src":require("@/assets/newHome/address.webp")}})])
}]

export { render, staticRenderFns }