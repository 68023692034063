<template>
  <div id="app" class="appOut">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <AppFooters v-if="$route.meta.isFooter"/>
    <van-popup class="loadingPop " :class="{newPop:$store.state.OptStatus}" v-model="$store.state.loadingShow"  :close-on-click-overlay="false">
      <div :class="{newBj:$store.state.OptStatus}">
            <div class="bouncing-loader">
            <div class="yuan">
              <img src="../src/assets/footBall.svg"/>
            </div>
            <div class="yuan"><img src="../src/assets/blackIcon.svg"/></div>
            <div class="yuan"><img src="../src/assets/slot.svg"/></div>
          </div>
      </div>
      <div class="loadingTxt" v-show="$store.state.loadTextShow">正在匹配中,请稍等…</div>
        <!-- <van-loading type="spinner" class="wmdxz" color="#0094ff" size="58" >
          <template #icon>
               <AppImage src="/icons/i_xzLoad.svg" />
          </template>
        </van-loading> -->
    </van-popup>
    <router-view/> 
  </div>
</template>

<script>
import {noFbtoken} from '@/request/sportApi'
import AppFooters from "@/components/footer.vue"
export default {
  components: {
    // HelloWorld
    AppFooters,
  },
  data(){
    return{
    }
  },

  methods:{
    
  },
  created(){
    if (!localStorage.getItem('requestUrl')) {
      noFbtoken()
      .then((res)=>{
        localStorage.setItem('serverInfo', JSON.stringify(res.data.serverInfo))
        localStorage.setItem('requestUrl', res.data.serverInfo.pushServerAddress)
      })
    }
  },
  mounted(){
    
  },
  computed: {
    
  }
}
</script>

<style lang="less">
html{
  // overscroll-behavior: none;
  
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // height: 100dvh;
  // background: #101B1F;
  // position: fixed;
  width: 100%;
  // overflow-y: scroll;
  // -webkit-overflow-scrolling: touch;
  // z-index: 2;
  
}

body{
  background: #202C37;
  // overflow: hidden;
  // position: relative;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
*{
  margin:0;
  border:0;
  box-sizing: border-box;
}
.contents{
  // background: radial-gradient(128.3% 54.62% at 131.6% -1.79%, #02568E 6%, #18222A 61.18%, #18222A 100%);
          background-repeat: no-repeat;
          background-size: cover;
          min-height: 100vh;
}
.loginContent{
  // background-image: url('@/assets/login/background.svg');
  background: #101B1F;
  background-repeat: no-repeat;
  background-size: cover;
          min-height: 100dvh;
          height: 100dvh;
}
.newPop{
  height: 100dvh!important;
  
}
.loadingPop{
      background-color:transparent!important;
      width:380px;
      height:280px;
    }
    .loadingTxt{
      font-size: 22px;
      color: #fff;
    }

    @keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -2rem, 0);
  }
}
.newBj{
  background-color: #202C37;
  width: 100vw;
  height: 100%;

}
.bouncing-loader {
  display: flex;
  justify-content: center;
}
.bouncing-loader > div {
  width: 0.8rem;
  height: 0.8rem;
  margin: 3rem 0.2rem;
  //background: #8385aa;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;

  img{
    width: 100%;
    height: 100%;
  }
}
.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}
.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
img{
  pointer-events: none;
}
.van-popover__arrow{
  border-bottom-color: rgba(82, 89, 119, 1)!important;
}
.van-popover__content{
  background-color: rgba(82, 89, 119, 1);
  border-radius: 4px!important;
}
</style>
