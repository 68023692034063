<template>
  <div class="footer" :class="{'heightOut':isApp()}">
    <!-- :style="{ 'left': active == 0 ? '-8%' : active == 1 ? '12%' : active == 2 ? '40%' : active == 3 ? '60%' : active == 4 ? '80%' : ''}" -->
    <!-- <div
      class="aftive"
      :style="{ left: active == 0 ? '-3.5%' : active * 20 - 3.5 + '%' }"
    >
      
    </div> -->
    <!-- <div class="tt"><img src="../assets/footer/aftiveT.svg"/></div>
        <div class="zj">
            <img src="../assets/footer/aftiveCenter.svg"/>
        </div> -->
    <div
      class="footList"
      v-for="(item, index) in list"
      :key="item.name + index"
      @click="$router.push({ path: item.link })"
      :class="{'noActive':true}"
    >
    <!-- :class="{'noActive':item.id!=active}" -->
      <div class="listImg" :class="{ activeImg: item.id == active }">
        <img
          :src="
            require(`@/assets/footer/${item.id == active ? 'white' : 'black'}${
              item.id
            }.svg`)
          "
        />
      </div>
      <div class="listTitle" :class="{ afterTitle: active == item.id }">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      active: 0,
      activeLink: "/",
      list: [
        {
          name: "精品",
          link: "/",
          id: 0,
        },
        {
          name: "福利",
          link: "/fulis",
          id: 1,
        },
        {
          name: "体育",
          link: "/sport",
          id: 2,
        },
        
        // {
        //   name: "优惠",
        //   link: "/details",
        //   id: 2,
        // },
        {
          name: "娱乐城",
          // link: "/PersonReal",
          link: "/recreation",
          id: 3,
        },
        
        {
          name: "我的",
          link: "/personal",
          id: 4,
        },
      ],
    };
  },
  methods: {
    isApp(){
      var origin =  window.location.origin
      return !origin.includes("app")
    },
    goPage(e) {
      if (navigator.vibrate) {
        navigator.vibrate(1000);
      } else if (navigator.webkitVibrate) {
        navigator.webkitVibrate(1000);
      }
      this.$router.push({ path: e });
    },
  },
  watch: {
    $route(to, form) {
      this.activeLink = to.path;
      for (var i = 0; i < this.list.length; i++) {
        if (to.path == this.list[i].link) {
          this.active = i;
        }
      }
      console.log(to, form);
    },
  },
  created() {
    console.log(this.$route.path);
    for (var i = 0; i < this.list.length; i++) {
      if (this.$route.path == this.list[i].link) {
        this.active = i;
      }
    }

    // this.$router.beforeEach((to, from, next) => {
    //   this.currentImage = this.images[to.path];
    //   next();
    // });
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100vw;
  height: 86px;
  flex-shrink: 0;
  background: #15212d;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 5px;
  z-index: 100;
  transition: all 1s;
 
  .footList {
    position: relative;
    z-index: 10;
    width: 20%;
    
    .listImg {
      width: 24px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 2px auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .activeImg {
      width: 24px;
      height: 20px;
    }

    .listTitle {
      color: #b3b9d2;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      /* 21px */
    }
    .afterTitle {
      color: #4774da;
    }
  }
  .noActive{
    padding-top: 10px;
  }

  .aftive {
    transition: all 0.5s;
    position: absolute;
    z-index: 8;
    background: url("../assets/footer/tupo.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 102.626px;
    height: 11px;
    flex-shrink: 0;
    top: -10px;

    .tt {
      width: 28px;
      height: 2px;
      flex-shrink: 0;
      border-radius: 0px 0px 4px 4px;
      background: #ef5249;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .zj {
      width: 50px;
      height: 32px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      fill: linear-gradient(180deg, rgba(243, 84, 75, 0.5) 0%, #cc3a31 100%);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.heightOut{
  height: 56px!important;
}
</style>
