<template>
  <div  class="tabItem">
    

    <div class="titleLine"  style="margin-top: 0;">
      <div class="titleImg">
        <img src="@/assets/newHome/gf.webp" />
      </div>
      <div class="titleName">情趣商店</div>
      <div class="titleTips">Sex Shop</div>
    </div>
    <div class="zbListOut">
      <div
        class="zbItem"
        v-for="(item, index) in fun"
        :key="index"
        @click="goLink(item.androidLink)"
      >
       
       
        <div class="zbImg">
          <img :src="`https://testapi.broshanwbhxbvx.top/${item.icon}`" />
        </div>
        <div class="dib">
            <div class="itemsName">{{ item.name }}</div>
            <div class="lines">
              <div class="moneys">￥<span>{{ Number(item.price).toFixed(2) }}</span></div>
              <div class="ysdsj">{{ `已售${item.downloadNumber}+件` }}</div>
            </div>
            <div class="lines">
              <div>

              </div>
              <div class="ysdsj">
                {{ `${item.reservation}+评价` }}
              </div>
            </div>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tabTwo",
  props: ["fun"],
  methods: {
    goLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
 .tabItem{
    background: rgba(20, 23, 32, 1);
    padding-top: 15px;
  }
 .titleLine {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 92%;
    margin: 0 auto 15px auto;
    gap: 5px;
  .titleImg {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .titleName {
    color: rgba(29, 154, 238, 1);
    font-family: "PingFang SC";
    font-weight: 400;
    font-size: 18px;
    height: 18px;
    line-height: 18px;
  }
  .titleTips {
    color: rgba(29, 154, 238, 1);
    font-family: "PingFang SC";
    font-weight: 400;
    font-size: 14px;
    height: 20px;
    line-height: 23px;
    text-transform: uppercase;
  }
}
.topListItem {
    width: 92%;

    display: grid;
    justify-content: flex-start;
    grid-template-columns: repeat(auto-fill, 17.391%);
    grid-row-gap: 10px;
    margin: 0 auto;
    grid-gap: 10px;
    .topItemList {
      width: 100%;

      .logoImg {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
      .topItemName {
        color: rgba(255, 255, 255, 1);
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 14px;
        margin-top: 4px;
        white-space: nowrap;  /* 不换行 */
        overflow: hidden; 
        //text-overflow: ellipsis; 
      }
      .inItems{
        width: 42px;
  height: 20px;
        border-radius: 20px;
        border: 1px solid rgba(179, 185, 210, 1);
        color: rgba(179, 185, 210, 1);
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  margin: 4px auto 0 auto;
      }
    }
  }
.zbListOut {
  display: grid;
  width: 92%;
  margin: 0 auto;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  .zbItem {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    .leftTop {
      position: absolute;
      top: 0px;
      left: -2px;
      background: url("@/assets/newHome/blue.webp") no-repeat;
      background-size: 100% 100%;
      width: 73px;
      height: 31px;
      color: rgba(255, 255, 255, 1);
      font-family: "Microsoft YaHei UI";
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      padding-right: 6px;
    }
    .rightTop {
      position: absolute;
      right: 6px;
      top: 4px;
      width: 46px;
      height: 16px;
      border-radius: 20px;
      background: rgba(37, 51, 80, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      color: rgba(255, 255, 255, 1);
      font-family: "Microsoft YaHei UI";
      font-weight: 400;
      font-size: 8px;
      .zbzImg {
        width: 12px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .zbImg {
      width: 100%;
      height: 165px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        border-radius: 8px 8px 0 0;
        width: 100%;
        height: 100%;
      }
    }
    .dib{
      width: 100%;
      height: 72px;
      
      background: rgba(29, 33, 47, 1);
      padding:  5px;
      border-radius: 0 0 8px 8px;
      .itemsName{
        color: rgba(255, 255, 255, 1);
        font-family: "PingFang SC";
        font-weight: 500;
        font-size: 14px;
        white-space: nowrap;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .lines{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
        
        .moneys{
          height: 12px;
          line-height: 12px;
          color: rgba(58, 134, 255, 1);
              font-family: DIN;
              font-weight: 700;
              font-size: 10px;
              line-height: 12px;
              display: flex;
              align-items: center;
          span{
            color: rgba(58, 134, 255, 1);
          font-family: DIN;
          font-weight: 700;
          font-size: 16px;
          line-height: 12px;
           
  
          }
        }
        .ysdsj{
          text-align: right;
  color: rgba(143, 146, 166, 1);
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 10px;
        }
      }
    }
    .playCs {
      position: absolute;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
