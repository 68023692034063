import Vue from 'vue'
import Vuex from 'vuex'
import sportModule from './sport.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showMessage:false,
    loadingShow:false,
    url:"",
    usdData:{},
    OptStatus:false,
    loadTextShow:false,
  },
  getters: {
  },
  mutations: {
    getUsdData(state,data){
      state.usdData = data;
    },
    setLoadingShowTrue(state){
      state.loadingShow= true;
    },
    setLoadingShowFalse(state){
      state.loadingShow= false;
    },
    setLoadingOpt0(state){
      state.OptStatus = false;
    },
    setLoadingOpt1(state){
      state.OptStatus = true;
    },
    setLoadTextShowTrue(state){
      state.loadTextShow = true;
    },
    setLoadTextShowFalse(state){
      state.loadTextShow = false;
    },
    setUrl(state,url){
      state.url = url;
    },
    getUrl(state){
      return state.url;
    },
    goService(state){
      state.loadingShow = true;
      setTimeout(()=>{
        state.loadingShow = false;
      },3000)
      
      window.open("https://secure.livechatinc.com/licence/18398763/open_chat.cgi",'_blank')

    },
  },
  actions: {
  },
  modules: {
    sport: sportModule
  }
})
