import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      isFooter: true,
    },
  },
  {
    path: "/recreation",
    name: "recreation",
    component: () =>
      import("../views/recreation.vue"),
    meta: {
      isFooter: true,
    },
  },
  
  {
    path: "/personReal",
    name: "personReal",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PersonReal.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/sports",
    name: "sportsIndex",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/sports.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/electronic",
    name: "ElectronicIndex",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Electronic.vue"),
    meta: {
      isFooter: false,
    },
  },

  {
    path: "/pescaria",
    name: "PescariaIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Pescaria.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/block",
    name: "BlockIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Block.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/setup",
    name: "SetupIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/setup/Setup.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/setPwd",
    name: "setPwd",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/setup/SetPwd.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/setFund",
    name: "setFund",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/setup/setFund.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/setEmail",
    name: "setEmail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/setup/setEmail.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/deposit",
    name: "depositIndex",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/deposit-with/deposit.vue"
      ),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/withdrawals",
    name: "withdrawalIndex",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/deposit-with/withdrawals.vue"
      ),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/record",
    name: "recordIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/record/index.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/record/content",
    name: "recordContent",
    props: true,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/record/content.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/record/postRecord",
    name: "postRecord",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/record/postRecord.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/account/index",
    name: "accountIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/account/index.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/account/addVirtual",
    name: "addVirtual",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/account/addVirtual.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/account/addLink",
    name: "addLink",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/account/addLink.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/welfare/index",
    name: "welfareIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/welfare/index.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/welfare/content",
    name: "welfareContent",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/welfare/content.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/share/index",
    name: "shareIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/share/index.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/info/index",
    name: "infoIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/info/index.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/info/content",
    name: "infoContent",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/info/content.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/login",
    name: "loginIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/login.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/register",
    name: "registerIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/register.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/recover",
    name: "recoverIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/recover.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/terms",
    name: "termsIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/terms.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/download",
    name: "downloadIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/download.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/setIdentity",
    name: "setIdentity",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/setup/setIdentity.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/classIndex",
    name: "classIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/class/index.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/depositContent",
    name: "depositContent",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/deposit-with/depositContent.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/threeDeposit",
    name: "threeDeposit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/deposit-with/threeDeposit.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/details",
    name: "detailsIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/activity/details.vue"),
    meta: {
      isFooter: true,
    },
  },
  {
    path: "/activity",
    name: "activityIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/activity/activity.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/overview",
    name: "overviewIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/record/overview.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/tutorial",
    name: "tutorialIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tutorial/index.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/sportsActivity",
    name: "sportsActivity",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/activity/sportsActivity.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/activityRule",
    name: "activityRule",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/activity/activityRule.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/personal",
    name: "personalIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/personal/index.vue"),
    meta: {
      isFooter: true,
    },
  },
  {
    path: "/vip",
    name: "vipIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/vip/index.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/vipRule",
    name: "vipRule",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/vip/rule.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/cooperation",
    name: "cooperationIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/cooperation/index.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/convert",
    name: "convertIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/deposit-with/convert.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/newSetup",
    name: "newSetup",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newSetUp/index.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/betraySell",
    name: "betraySell",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tutorial/betraySell.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/fulis",
    name: "fuliIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/fuli.vue"),
    meta: {
      isFooter: true,
    },
  },
  {
		path: "/sport",
		name: "sportIndex",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/sport/index.vue"),
		meta: {
			isFooter: true,
		},
	},
	// {
	// 	path: "/sportdetails",
	// 	name: "sportDetails",
	// 	component: () =>
	// 		import( /* webpackChunkName: "about" */ "../views/sport/details.vue"),
	// 	meta: {
	// 		isFooter: false,
	// 	},
	// },
	{
		path: "/jiaocheng",
		name: "jiaoCheng",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/sport/jiaocheng.vue"),
		meta: {
			isFooter: false,
		},
	},
  {
		path: "/setHeadImg",
		name: "setHeadImg",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/personal/setHeadImg.vue"),
		meta: {
			isFooter: false,
		},
	},
  {
		path: "/prefix",
		name: "prefixIndex",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/prefix.vue"),
		meta: {
			isFooter: false,
		},
	},
  {
    path: "/rightSlider",
    name: "rightSlider",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/sport/components/rightSlider.vue"),
    meta: {
      isFooter: false,
    },
  },
  {
    path: "/saiguo",
    name: "saiguo",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/sport/components/saiguo.vue"),
    meta: {
      isFooter: false,
    },
  },
  
  
];

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function  push(to) {
  return VueRouterPush.call(this,to).catch(err => err)
}

const router = new VueRouter({
  mode: "hash",//原先history  hash
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 如果有 savedPosition，则恢复到之前的滚动位置
    if (savedPosition) {
      return savedPosition
    } else {
      // 否则滚动到页面顶部
      return { x: 0, y: 0 }
    }
  }
});
router.beforeEach((to, from, next) => {
  // 如果是前进路由，则滚动到页面顶部
  if (router.app.$route && to.path !== router.app.$route.path) {
    const appId = document.querySelector("#app")
    // if(to.path != '/classIndex'){
      if(appId){
        appId.scrollTo(0,0)
      }
    // }

    
    // window.scrollTo(0, 0);
  }
  next();
});
export default router;
