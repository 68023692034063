import { post, get, } from "@/request/navIndex";
//import qs from "qs";

// 导航
export function navGetClientGetProduct(options) {
  return get("/client/getProduct", options);
}

// 个人信息
export function userInfo(options) {
  return post("/client/user/info", options);
}


// 视频
export function getVideo(options) {
  return post("/product/getVideo", options);
}

