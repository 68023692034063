import {
	post
} from "@/request/index";
import request from '@/request/sportIndex'

// 获取体育的token
export function fbtoken() {
	return post("/pdgame/fb/token");
}
export function noFbtoken() {
	return post("/pdgame/fb/url");
  }

// 获取首页firset tabs的分类
export function statistical(data) {
	return request({
		method: 'post',
		url: '/v1/match/statistical',
		data
	})
}

// 获取首页game数据
export function getList(data) {
	return request({
		method: 'post',
		url: '/v1/match/getList',
		data
	})
}


// 获取联赛列表
export function getOnSaleLeagues(data) {
	return request({
		method: 'post',
		url: '/v1/match/getOnSaleLeagues',
		data
	})
}



// 获取早盘时间列表
export function matchCount(data) {
	return request({
		method: 'post',
		url: '/v1/match/matchCount',
		data
	})
}


// 获取单个赛事详情及玩法
export function getMatchDetail(data) {
	return request({
		method: 'post',
		url: '/v1/match/getMatchDetail',
		data
	})
}


// 投注前查询指定玩法赔率
export function batchBetMatchMarketOfJumpLine(data) {
	return request({
		method: 'post',
		url: '/v1/order/batchBetMatchMarketOfJumpLine',
		data
	})
}


// 预约投注下单
export function bet(data) {
	return request({
		method: 'post',
		url: '/v1/order/reserve/bet',
		data
	})
}


// 单关投注接口，可批量
export function singlePass(data) {
	return request({
		method: 'post',
		url: '/v1/order/bet/singlePass',
		data
	})
}


// 结算列表
export function list(data) {
	return request({
		method: 'post',
		url: '/v1/order/new/bet/list',
		data
	})
}

// 预约结算列表
export function betList(data) {
	return request({
		method: 'post',
		url: '/v1/order/reserve/betList',
		data
	})
}

// 获取预约投注限额配置
export function getBetParameter(data) {
	return request({
		method: 'post',
		url: '/v1/order/reserve/getBetParameter',
		data
	})
}


// 修改预约订单
export function update(data) {
	return request({
		method: 'post',
		url: '/v1/order/reserve/update',
		data
	})
}

// 取消预约订单
export function cancel(data) {
	return request({
		method: 'post',
		url: '/v1/order/reserve/cancel',
		data
	})
}


// 批量获取订单提前结算报价
export function price(data) {
	return request({
		method: 'post',
		url: '/v1/order/cashOut/price',
		data
	})
}

// 预约提前结算投注
export function cashOutReserve(data) {
	return request({
		method: 'post',
		url: '/v1/order/cashOutReserve/bet',
		data
	})
}

// 提前结算
export function earlySettlementBet(data) {
	return request({
		method: 'post',
		url: '/v1/order/earlySettlementBet',
		data
	})
}

// 串关投注接口
export function betMultiple(data) {
	return request({
		method: 'post',
		url: '/v1/order/betMultiple',
		data
	})
}


// 批量获取投注订单状态
export function getStakeOrderStatus(data) {
	return request({
		method: 'post',
		url: '/v1/order/getStakeOrderStatus',
		data
	})
}


// 取消提前结算预约订单
export function cashCancel(data) {
	return request({
		method: 'post',
		url: '/v1/order/cashOutReserve/cancel',
		data
	})
}


// 赛过详情
export function matchResultInfo(data) {
	return request({
		method: 'post',
		url: '/v1/match/matchResultInfo',
		data
	})
}

// 搜索
export function queryMatchByRecommend(data) {
	return request({
		method: 'post',
		url: '/v1/match/queryMatchByRecommend',
		data
	})
}

