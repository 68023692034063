<template>
  <div class="navIndex">
    <div class="bjzw"></div>
    <div class="toptop">
      <div class="topggIn">
        <div class="ggImg">
          <img src="@/assets/newHome/gg.webp" />
          
        </div>
        <div class="gdlie scroll-container">
            <div class="scroll-text">我们承诺App绝对绿色、安全, 且不会对您的数据和资产造成任何损害，请放心安装使用。</div>
          </div>
      </div>
    </div>
    <div class="swiperOut">
      <van-swipe class="gameWork" :autoplay="3000" :show-indicators="false">
        <van-swipe-item v-for="(image, index) in banner" :key="index" @click="goLink(image.androidLink)">
          <!-- v-lazy="image" -->
          <img class="workImg"  :src="`https://testapi.broshanwbhxbvx.top/${image.icon}`" />
        </van-swipe-item>
      </van-swipe>
    </div>

    <van-tabs v-model="active" swipeable  sticky>
      
      <van-tab >
        <template #title>
          <div class="tabIn"  :class="{actTab:active == 0}">
            看片
          </div>
        </template>
       <Tab1 :homeHorizontal="homeHorizontal" :homeSameCityVertical="homeVertical"></Tab1>
       <Tab4 :fun="fun"></Tab4>
      </van-tab>
      <van-tab >
        <template #title>
          <div class="tabIn"  :class="{actTab:active == 1}">
            视频
          </div>
        </template>
       <Tab1 :homeHorizontal="videoHorizontal" :homeSameCityVertical="videoVertical"></Tab1>
      </van-tab>
      <van-tab >
        <template #title>
          <div class="tabIn" :class="{actTab:active == 2}">
            直播
          </div>
        </template>
        
        <Tab2 :liveHorizontal="liveVertical" :homeHorizontal="liveHorizontal"></Tab2>
      </van-tab>
      <van-tab >
        <template #title>
          <div class="tabIn" :class="{actTab:active == 3}">
            约炮
          </div>
        </template>
        <Tab3 :homeSameCityVertical="recruitment"></Tab3>
      </van-tab>

      <van-tab >
        <template #title>
          <div class="tabIn" :class="{actTab:active == 4}">
            情趣商城
          </div>
        </template>
        
        <Tab4 :fun="fun"></Tab4>
      </van-tab>
    </van-tabs>
    <!-- <div class="tabZw">
      <div class="tabOut">
      <div class="tabItem" v-for="(item, index) in tabItem" :key="index">
        <div class="tabIcon">
          <img :src="item.icon" />
        </div>
        <div class="tabName">{{ item.name }}</div>
        <div class="blueTiao"></div>
      </div>
    </div>
    </div> -->
  </div>
</template>

<script>
import Tab1 from '@/components/nav/tab1.vue';
import Tab2 from '@/components/nav/tab2.vue';
import Tab3 from '@/components/nav/tab3.vue';
import Tab4 from '@/components/nav/tab4.vue';
import { navGetClientGetProduct } from "@/request/navApi";

export default {
  name: "newHome",
  components:{
    Tab1,Tab2,Tab3,
    Tab4
  },
  data() {
    return {
      tabItem: [
        {
          id: 0,
          name: "看片利器",
          icon: "",
        },
        {
          id: 1,
          name: "成人直播",
          icon: "",
        },
        {
          id: 2,
          name: "情趣约会",
          icon: "",
        },
      ],
      labelList: ["鸳鸯浴", "娇喘销魂", "浴室迷情", "制服", "角色扮演"],
      homeHorizontal: [],
      homeSameCityVertical: [],
      liveHorizontal:[],
      active:0,
      recruitment:[],
      homeVertical:[],
      videoHorizontal:[],
      videoVertical:[],
      liveVertical:[],
      fun:[],
      banner:[],
    };
  },
  mounted() {
    if (this.$route.query.code !== undefined) {
      localStorage.setItem("myCode", this.$route.query.code);
    }
    if (this.$route.query.url !== undefined) {
      localStorage.setItem("inUrl", this.$route.query.url);
    }
    this.getNavGetClientGetProduct();
  },
  methods: {
    getNavGetClientGetProduct() {
      this.$store.commit("setLoadingShowTrue");
      navGetClientGetProduct().then((res) => {
        if (res.code == 0) {
          this.homeHorizontal = res.data.homeHorizontal;
          this.homeSameCityVertical = res.data.homeSameCityVertical;
          this.homeVertical = res.data.homeVertical;
          this.videoHorizontal = res.data.videoHorizontal;
          this.videoVertical = res.data.videoVertical;
          this.liveHorizontal = res.data.liveHorizontal;
          this.recruitment = res.data.recruitment;
          this.liveVertical = res.data.liveVertical;
          this.fun = res.data.fun;
          this.banner = res.data.banner;
        }
        this.$store.commit("setLoadingShowFalse");
      });
    },
    
    goLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.navIndex {
  padding-bottom: 80px;
  background: rgba(20, 23, 32, 1);
  min-height: 100dvh;
  .bjzw{
    width: 100%;
    height: 214px;
    background: linear-gradient(180deg, rgba(31, 33, 41, 1) 0%, rgba(49, 51, 60, 1) 100%);
    position: absolute;
    top: 0;
    z-index: 0;
  }
  .toptop{
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    top: 0; /* 当页面滚动时，div 将保持在视口顶部 */
    z-index: 10;
    width: 100%;
    height: 40px;
    
    
    .topggIn{
      border-radius: 20px;
      background: rgba(29, 33, 47, 0.6);
      height: 24px;
      width: 92%;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      .gdlie{
        width: 87.82%;
        height: 24px;
        background: transparent;
      }
      .ggImg{
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .tabZw {
    width: 100%;
    height: 46px;
    position: relative;
  }
  .tabOut {
    position: fixed;
    width: 100%;
    height: 46px;
    border-bottom: 1px solid rgba(29, 33, 47, 1);
   
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    .tabItem {
      min-width: 81px;
      height: 46px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      .tabIcon {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tabName {
        color: rgba(29, 154, 238, 1);
        font-family: "Microsoft YaHei UI";
        font-weight: 700;
        font-size: 14px;
      }
      .blueTiao {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 3px;
        background: linear-gradient(
          180deg,
          rgba(132, 202, 255, 1) 0%,
          rgba(29, 154, 238, 1) 100%
        );
      }
    }
  }
 
}
/deep/.van-tabs__nav{
  // background: transparent;
}
/deep/.van-tab{
  border-bottom: 1px solid rgba(29, 33, 47, 1);
    // background: linear-gradient(
    //   180deg,
    //   rgba(31, 33, 41, 1) 0%,
    //   rgba(49, 51, 60, 1) 100%
    // );
    background: linear-gradient(180deg, #2e3039 0%, #30323b 100%);
    .van-tab__text{
      color: rgba(167, 178, 214, 1);font-family: "Microsoft YaHei UI";
      font-weight: 400;
      font-size: 14px;
    }
   
    
}
/deep/.van-tabs__line{
      width: 30px;
      background: #209bee!important;
    }
    .tabIn{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      transition: all 0.2s ease;
      font-size: 15px;
      font-weight: 400;
    }
    .actTab{
  
      box-shadow: 0px 2px 2px rgba(20, 22, 28, 1);
  background: linear-gradient(180deg, rgba(242, 250, 255, 1) 0%, rgba(29, 154, 238, 1) 100%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-family: "Microsoft YaHei UI";
  font-weight: 700;
  font-size: 15px;
    }

    .scroll-container{
      width: 100%;
      overflow: hidden; /* Hide the overflow text */
      background: #f5f5f5;
      white-space: nowrap; /* Prevent text wrapping */
      height: 24px;
      line-height: 24px;
      display: flex;
      align-items: center;
    }
    .scroll-text {
      display: inline-block;
      padding-left: 0%; /* Start off-screen */
      animation: scroll-left 10s linear infinite; /* Animate */
      color: rgba(255, 204, 0, 1);
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 11px;
      line-height: 24px;
    }
    @keyframes scroll-left {
      from {
        transform: translateX(100%); /* Start position */
      }
      to {
        transform: translateX(-100%); /* End position */
      }
    }

    .swiperOut{
      width: 92%;
      margin: 5px auto ;
      border-radius: 12px;
      height: 120px;
      background: rgba(40, 54, 67, 1);
      position: relative;
      z-index: 1;
      .gameWork{
        height: 100%;
        .workImg{
          width: 100%;
          height: 100%;
          border-radius: 12px;
        }
      }
    }
</style>
