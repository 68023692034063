<template>
  <div class="tabItem">
    <div class="titleLine" style="margin-top: 0;">
      <div class="titleImg">
        <img src="@/assets/newHome/gf.webp" />
      </div>
      <div class="titleName">官方推荐</div>
      <div class="titleTips">recommend</div>
    </div>
    <div class="topListItem">
      <div
        class="topItemList"
        v-for="(item, index) in homeHorizontal"
        :key="index"
        @click="goLink(item.androidLink)"
      >
        <div class="logoImg">
          <img :src="`https://testapi.broshanwbhxbvx.top/${item.icon}`" />
        </div>
        <div class="topItemName">{{ item.name }}</div>
        <div class="inItems">进入</div>
      </div>
    </div>
    <div class="titleLine" style="margin-bottom: 5px">
      <div class="titleImg">
        <img src="@/assets/newHome/title2.webp" />
      </div>
      <div class="titleName">情趣约炮</div>
      <div class="titleTips">online live</div>
    </div>
    <div
      class="bomList"
      v-for="(item, index) in homeSameCityVertical"
      :key="index"
      :style="{
        'border-bottom':
          index == homeSameCityVertical.length - 1
            ? ''
            : '1px solid rgba(29, 33, 47, 1)',
      }"
    >
      <div class="listLeft">
        <img
          class="imgZt"
          :src="`https://testapi.broshanwbhxbvx.top/${item.icon}`"
        />
        <div class="leftPop">
          <div class="dwIcon">
            <img src="@/assets/newHome/address.webp" />
          </div>
          <div>{{ `附近: ${item.distance}km` }}</div>
        </div>
      </div>
      <div class="listRight">
        <div class="listRightOne">
          <div class="blueShu"></div>
          <div class="rightName">{{ item.name }}</div>
          <div class="ages">{{ `${item.age}岁` }}</div>
          <div class="mmSw">{{ item.price }}</div>
        </div>
        <div class="listRightOne rightTwo">
          <div class="rightTwoTit">价格</div>
          <div class="mmMoney">
            <span>{{ getCiMoney(item.describe) }}</span
            >/次
          </div>
          <div class="mmMoney">
            <span>{{ getDeyMoney(item.describe) }}</span
            >/夜
          </div>
        </div>
        <div class="labelLine">
          <div
            class="labels"
            v-for="(desc, indexTwo) in setStringToAny(item.project)"
            :key="indexTwo"
            :style="{
              background:
                indexTwo == 0
                  ? 'rgba(227, 95, 0, 0.15)'
                  : indexTwo == 1
                  ? 'rgba(135, 75, 255, 0.15)'
                  : 'rgba(58, 134, 255, 0.15)',
              color:
                indexTwo == 0
                  ? 'rgba(227, 95, 0, 1)'
                  : indexTwo == 1
                  ? 'rgba(135, 75, 255, 1)'
                  : 'rgba(29, 154, 238, 1)',
            }"
          >
            {{ desc }}
          </div>
        </div>
        <van-button class="btn" @click="goLink(item.androidLink)">
          <div class="btnIn">
            <div class="btnIcon">
              <img src="@/assets/newHome/btnIcon.webp" />
            </div>
            <div>点击开始约会</div>
          </div>
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"tabOne",
    props:['homeSameCityVertical','homeHorizontal'],
    methods:{
      setStringToAny(label) {
      console.log(label.length);
      if (label.length <= 0) {
        return [];
      }
      return label.split("，");
    },
    getCiMoney(string) {
      if (string.length <= 0) {
        return;
      }
      let parts = string.split("-");
      return parts[0];
    },
    getDeyMoney(string) {
      if (string.length <= 0) {
        return;
      }
      let parts = string.split("-");
      return parts[1];
    },
    goLink(url) {
      window.open(url, "_blank");
    },
    }
};
</script>

<style lang="less" scoped>
  .tabItem{
    background: rgba(20, 23, 32, 1);
    padding-top: 15px;
  }
 .titleLine {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 92%;
    margin:  15px auto;
    gap: 5px;
    
    .titleImg {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .titleName {
      color: rgba(29, 154, 238, 1);
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 18px;
      height: 18px;
      line-height: 18px;
    }
    .titleTips {
      color: rgba(29, 154, 238, 1);
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 14px;
      height: 20px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }
  .topListItem {
    width: 92%;

    display: grid;
    justify-content: flex-start;
    grid-template-columns: repeat(auto-fill, 17.391%);
    grid-row-gap: 10px;
    margin: 0 auto;
    grid-gap: 10px;
    .topItemList {
      width: 100%;

      .logoImg {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
      .topItemName {
        color: rgba(255, 255, 255, 1);
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 14px;
        margin-top: 4px;
        white-space: nowrap;  /* 不换行 */
        overflow: hidden; 
        //text-overflow: ellipsis; 
      }
      .inItems{
        width: 42px;
  height: 20px;
        border-radius: 20px;
        border: 1px solid rgba(179, 185, 210, 1);
        color: rgba(179, 185, 210, 1);
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  margin: 4px auto 0 auto;
      }
    }
  }
  .bomList {
    width: 92%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;


    padding-bottom: 10px;
    padding-top: 10px;
    .listLeft {
      width: 136px;
      height: 136px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      position: relative;
      overflow: hidden;
      margin-right: 15px;
      flex-shrink: 0;
      .imgZt {
        border-radius: 16px;
        width: 136px;
        height: 136px;
      }
      .leftPop {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(255, 255, 255, 1);
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 10px;
        background: radial-gradient(
          164.36% 100% at 50% 100%,
          rgba(51, 51, 51, 1) 0%,
          rgba(51, 51, 51, 0) 100%
        );
        .dwIcon {
          width: 10px;
          height: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 3px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .listRight {
      text-align: left;
      .listRightOne {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        .blueShu {
          width: 4px;
          height: 16px;
          border-radius: 2px;
          background: rgba(29, 154, 238, 1);
        }
        .rightName {
          color: rgba(255, 255, 255, 1);
          font-family: "PingFang SC";
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
        }
        .ages {
          min-width: 32px;
          height: 17px;
          border-radius: 20px;
          background: rgba(255, 101, 141, 0.15);
          color: rgba(255, 101, 141, 1);
          font-family: "Microsoft YaHei UI";
          font-weight: 400;
          font-size: 10px;
          text-align: center;
          line-height: 17px;
          
        }
        .mmSw {
          min-width: 68px;
          height: 17px;
          border-radius: 20px;
          background: rgba(29, 33, 47, 1);
          color: rgba(128, 136, 162, 1);
          font-family: "Microsoft YaHei UI";
          font-weight: 400;
          font-size: 10px;
          text-align: center;
          line-height: 17px;
        }
        .rightTwoTit {
          color: rgba(123, 127, 154, 1);
          font-family: "PingFang SC";
          font-weight: 400;
          font-size: 10px;
        }
        .mmMoney {
          color: rgba(255, 255, 255, 1);
          font-family: "PingFang SC";
          font-weight: 400;
          font-size: 10px;
          span {
            color: rgba(255, 255, 255, 1);
            font-family: DIN;
            font-weight: 700;
            font-size: 14px;
          }
        }
      }
      .rightTwo {
        margin-top: 5px;
        margin-bottom: 2px;
      }
      .labelLine {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 3px;

        .labels {
          padding: 0 4px;
          min-width: 39px;
          height: 17px;
          border-radius: 2px;
          font-family: "PingFang SC";
          font-weight: 400;
          font-size: 10px;
          line-height: 17px;
          text-align: center;
        }
      }
      .btn {
        width: 132px;
        height: 36px;
        border-radius: 20px;
        border-width: 1px;
        border: 0;
        box-sizing: border-box;
        margin-top: 10px;
        background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(255, 255, 255, 0.2) 100%
          ),
          linear-gradient(
            180deg,
            rgba(81, 229, 255, 1) 0%,
            rgba(0, 95, 252, 1) 100%
          );
        .btnIn {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          color: rgba(255, 255, 255, 1);
          font-family: "PingFang SC";
          font-weight: 400;
          font-size: 12px;

          .btnIcon {
            width: 14px;
            height: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
</style>
